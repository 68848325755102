<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <!-- Creating a user is a restricted action - it may require reauthentication -->
  <!-- Show the create user dialog only after re-authentication -->
  <RestrictedAccessWrapper
    v-slot="{ executeRestrictedApi }"
    :eager="true"
  >
    <RSModalForm
      :active="true"
      subject="Create User"
      @close="$emit('close')"
      @submit="onSubmit(executeRestrictedApi)"
    >
      <template #content>
        <fieldset :disabled="processing">
          <div
            v-if="status.show"
            class="rs-field"
          >
            <EmbeddedStatusMessage
              :message="status.message"
              :type="status.type"
              data-automation="ucd-status-message"
              @close="hideStatusMessage"
            />
          </div>
          <RSInputText
            v-model.trim="form.username"
            :message="errorMessage('username')"
            label="Username"
            name="ucd-username"
            data-automation="ucd-username"
            autocomplete="off"
          />
          <RSInputText
            v-model.trim="form.firstName"
            label="First Name"
            data-automation="ucd-first-name"
            name="ucd-first-name"
          />
          <RSInputText
            v-model.trim="form.lastName"
            label="Last Name"
            data-automation="ucd-last-name"
            name="ucd-last-name"
          />
          <RSInputText
            v-model.trim="form.email"
            :message="errorMessage('email')"
            label="Email"
            data-automation="ucd-email"
            name="ucd-email"
            type="email"
          />
          <RSInputSelect
            v-model="form.userRole"
            :options="roleOptions"
            label="Role"
            data-automation="ucd-role"
            name="ucd-role"
          />
        </fieldset>
      </template>
      <template #controls>
        <RSButton
          id="ucd-submit"
          label="Create User"
          data-automation="ucd-submit"
        />
      </template>
    </RSModalForm>
  </RestrictedAccessWrapper>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSInputSelect from '@/elements/RSInputSelect';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';
import { useVuelidate } from '@vuelidate/core';

import { sendOrGetAccountConfirmationLink } from '@/api/authentication';
import UserRoles from '@/api/dto/userRole';
import { safeAPIErrorMessage } from '@/api/error';
import { addNewLocalUser } from '@/api/users';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import { SHOW_INFO_MESSAGE } from '@/store/modules/messages';
import { ValidationMessages } from '@/utils/validation';
import { emailValidator, usernameValidator } from '@/utils/validators';
import { mapActions } from 'vuex';

export default {
  name: 'UserCreateDialog',
  components: {
    RestrictedAccessWrapper,
    EmbeddedStatusMessage,
    RSButton,
    RSInputText,
    RSInputSelect,
    RSModalForm,
  },
  props: {
    serverSettings: {
      type: Object,
      required: true,
    },
    currentUserRole: {
      type: Number,
      required: true,
    },
  },
  emits: ['close', 'confirmation-link', 'user-created'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      processing: false,
      activityTimeoutId: null,
      form: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        userRole: 'viewer',
      },
      status: {
        show: false,
        message: null,
        type: null,
      },
    };
  },
  computed: {
    roleOptions() {
      const roles = [
        { value: 'viewer', label: 'Viewer' },
        { value: 'publisher', label: 'Publisher' },
        { value: 'administrator', label: 'Administrator' },
      ];
      const allowedRoles = [];
      roles.forEach(role => {
        if (this.currentUserRole >= UserRoles.of(role.value)) {
          allowedRoles.push(role);
        }
      });
      return allowedRoles;
    },
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    async onSubmit(executeRestrictedApi) {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        // invalid form, bail
        return;
      }

      this.processing = true;
      this.activityTimeoutId = setTimeout(
        () => this.showStatusMessage('activity'),
        300
      );

      return executeRestrictedApi(addNewLocalUser(this.form))
        .then(this.onSuccess)
        .catch(this.onError);
    },

    async onSuccess(user) {
      try {
        if (this.serverSettings.mailConfigured) {
          // email configured
          this.setInfoMessage({
            message: `Successfully added user. Sent email to ${user.email} with an account confirmation link.`,
            autoHide: false,
          });
        } else {
          // email not configured
          const { url } = await this.getConfirmationLink(user);
          this.$emit('confirmation-link', url);
        }
        this.$emit('user-created', user);
      } catch (err) {
        this.showStatusMessage('error', err.message);
      } finally {
        clearTimeout(this.activityTimeoutId);
        this.processing = false;
      }
    },

    onError(err) {
      this.processing = false;
      clearTimeout(this.activityTimeoutId);

      if (!(err instanceof ReauthenticationInProgressError)) {
        this.showStatusMessage('error', safeAPIErrorMessage(err));
      }
    },

    getConfirmationLink(user) {
      return sendOrGetAccountConfirmationLink(user.guid).catch(err => {
        if (!err.response && !err.request) {
          // not API error
          console && console.error && console.error(err);
        }
        // not really an error, just failed to get confirmation link
        this.setInfoMessage({
          message: `Successfully added user, however failed to obtain the user's account confirmation link.
            Please contact your Posit Connect administrator.`,
          autoHide: false,
        });
        return { url: null };
      });
    },

    errorMessage(field) {
      const fieldValidators = this.v$.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      const firstFail = fieldValidators.$errors[0].$validator;
      return ValidationMessages[field][firstFail];
    },

    showStatusMessage(type, message) {
      this.status.show = true;
      this.status.type = type;
      this.status.message = message;
    },

    hideStatusMessage() {
      this.status.show = false;
    },
  },
  validations() {
    return formValidations.call(this);
  },
};

// returns validators for fields in the form.
// Note: building the validators is extracted outside the vue component so the
// set of validators is accessible from within the `errorMessage` component
// method and the `validations` vuelidate mixin-method - component methods
// cannot call the `validations()` mixin-method.
function formValidations() {
  const validations = {
    form: {
      username: usernameValidator(this.serverSettings),
      email: emailValidator(),
    },
  };
  return validations;
}
</script>
