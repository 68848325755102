<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <aside
    ref="optionsPanel"
    :style="style"
    class="rsc-content-list__options"
    aria-labelledby="header-title"
  >
    <div class="rsc-options-panel__header">
      <h2
        id="header-title"
        class="rsc-options-panel__header-title"
      >
        Options
      </h2>
      <button
        aria-label="Hide options panel"
        class="paneCloser"
        @click="toggleOptions"
      />
    </div>

    <div class="rsc-content-list__options-search">
      <RSInputSearch
        ref="searchInput"
        :show-label="false"
        :model-value="filters.search"
        label="Search for content"
        placeholder="Search for content..."
        :small="true"
        :show-clear="Boolean(filters.search)"
        name="cop-search"
        data-automation="cop-search"
        icon="search"
        @change="onSearchChange"
        @clear="clearSearch"
      />
    </div>

    <div
      v-if="hasVisibilityFilter"
      class="rs-field"
    >
      <RSRadioGroup
        :title="visibilityTitle"
        :options="visibilityOptions"
        :model-value="visibilityOptionValue"
        name="cop-visibility"
        data-automation="visibility-filter"
        @change="onVisibilityChange"
      />
    </div>

    <div class="rsc-content-list__options-tags rs-field">
      <TagsCatalogSelector
        :selected="selectedTags"
        :prefetched="true"
        @change="onTagChange"
      />
    </div>

    <div class="rs-field">
      <RSRadioGroup
        title="Content Type"
        :options="contentTypeOptions"
        :model-value="filters.contentType"
        name="cop-content-type"
        data-automation="content-type-filter"
        @change="onContentTypeChange"
      />
    </div>
  </aside>
</template>

<script>
import RSInputSearch from '@/elements/RSInputSearch';
import RSRadioGroup from '@/elements/RSRadioGroup';
import { mapMutations, mapState } from 'vuex';

import AppRoles from '@/api/dto/appRole';
import OptionsPanel from '@/components/OptionsPanel';
import TagsCatalogSelector from '@/components/TagsCatalogSelector';
import {
  CONTENT_LIST_TOGGLE_OPTIONS,
  CONTENT_LIST_UPDATE_FILTER,
  CONTENT_LIST_UPDATE_START_PAGE,
  FilterType,
} from '@/store/modules/contentList';
import { contentTypes } from '@/utils/contentList';
import { debounce } from '@/utils/debounce';
import { tagQueryFilterToObject } from './contentListUtils';

const visibilityType = {
  viewer: 'viewer',
  editor: 'editor',
  owner: 'owner',
};

const visibilityValues = {
  admin: [
    { value: visibilityType.viewer, label: 'Visible to You' },
    { value: visibilityType.editor, label: 'Content You Own' },
    { value: visibilityType.owner, label: 'All Server Content' },
  ],
  publisher: [
    { value: visibilityType.viewer, label: 'All' },
    { value: visibilityType.editor, label: 'You' },
  ],
};

const contentTypeValues = [
  'all',
  'application',
  'document',
  'plot',
  'pin',
  'api',
];

export default {
  name: 'ContentOptionsPanel',
  components: { RSRadioGroup, RSInputSearch, TagsCatalogSelector },
  extends: OptionsPanel,
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
  emits: ['filter'],
  computed: {
    ...mapState({
      filters: state => state.contentList.filters,
    }),
    hasVisibilityFilter() {
      return !this.currentUser.isViewer();
    },
    contentTypeOptions: () => contentTypeValues.map(value => ({
      label: contentTypes[value],
      value,
    })),
    visibilityOptions() {
      return this.currentUser.isPublisher() ? visibilityValues.publisher : visibilityValues.admin;
    },
    visibilityTitle() {
      return this.currentUser.isPublisher() ? 'Owner' : 'Visibility';
    },
    visibilityOptionValue() {
      return AppRoles.stringOf(this.filters.visibility);
    },
    selectedTags() {
      const { tags } = this.filters;
      const tagsToSet = Object.keys(tags).flatMap(key => tags[key].selected);
      return new Set(tagsToSet);
    },
  },
  methods: {
    ...mapMutations({
      updateFilter: CONTENT_LIST_UPDATE_FILTER,
      updateStartPage: CONTENT_LIST_UPDATE_START_PAGE,
      toggleOptions: CONTENT_LIST_TOGGLE_OPTIONS,
    }),
    // Called externally!
    focus() {
      this.$nextTick(() => this.$refs.searchInput.focusElement());
    },
    clearSearch() {
      this.updateFilter({ type: FilterType.SEARCH, value: '' });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
    onSearchChange: debounce(300, function(value) {
      this.updateFilter({ type: FilterType.SEARCH, value });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    }),
    onVisibilityChange(value) {
      this.updateFilter({
        type: FilterType.VISIBILITY,
        value: AppRoles.of(value),
      });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
    onContentTypeChange(value) {
      this.updateFilter({
        type: FilterType.CONTENT_TYPE,
        value,
      });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
    onTagChange({ categoryId, categoryFilter, categoryName, selectedTags }) {
      const tags = JSON.parse(JSON.stringify(this.filters.tags));
      const categoryIsInStore = Boolean(tags[categoryId]);
      const tag = tagQueryFilterToObject(
        categoryFilter,
        categoryName,
        selectedTags
      );

      if (!categoryFilter && categoryIsInStore) {
        // remove tag category
        delete tags[categoryId];
      } else {
        // add or update tag category
        tags[categoryId] = tag;
      }

      this.updateFilter({ type: FilterType.TAGS, value: tags });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
  },
};
</script>
