<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <h2 class="sectionTitle">
      Published Content
    </h2>
    <div
      v-if="status.message"
      class="rs-field"
    >
      <EmbeddedStatusMessage
        :type="status.type"
        :message="status.message"
        :show-close="false"
      />
    </div>
    <div
      v-if="!api.applications.length"
      class="emptyListMessage"
    >
      This user has not published any content.
    </div>
    <RSTable
      v-if="api.applications.length"
      :columns="tableColumns"
      table-name="Published Content"
    >
      <RSTableRow
        v-for="app in api.applications"
        :key="app.guid"
      >
        <RSTableCell
          :cell-id="`app-name-${app.guid}`"
          :has-icon="true"
          :fill="true"
          data-automation="pcl-content-name"
        >
          <router-link
            :to="{ name: 'apps', params: { idOrGuid: app.guid } }"
          >
            <div class="content-table__content-name">
              <div>
                <i
                  :class="app.iconClass()"
                  class="rs-icon rs-icon--large"
                />
              </div>
              <div class="content-table__display-name">
                {{ app.displayName || 'Untitled' }}
              </div>
            </div>
          </router-link>
        </RSTableCell>
        <RSTableCell data-automation="pcl-content-type">
          {{ contentDescriptions[app.contentType()] }}
        </RSTableCell>
        <RSTableCell data-automation="pcl-content-deployed-time">
          {{ lastDeployedTime(app) }}{{ fromGit(app.git) }}
        </RSTableCell>
      </RSTableRow>
    </RSTable>

    <RSPager
      v-show="showPager"
      :disable-left-actions="disablePreviousPagination"
      :disable-right-actions="disableNextPagination"
      :labels="pagerLabels"
      @first-page="gotoPage('first')"
      @previous-page="gotoPage('previous')"
      @next-page="gotoPage('next')"
      @last-page="gotoPage('last')"
    />
  </div>
</template>

<script>
import RSPager from '@/elements/RSPager';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';

import { getPublishedContentForUser } from '@/api/content';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { activeTime } from '@/utils/activeTime.filter';
import { contentDescriptions } from '@/utils/contentList';
import { fromGit } from '@/utils/contentList.js';

// number of records to display at a time
const maxRecords = 10;

export default {
  name: 'PublishedContentList',
  components: {
    EmbeddedStatusMessage,
    RSPager,
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  props: {
    userGuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contentDescriptions,
      status: {
        message: null,
        type: null,
      },
      api: {
        applications: [],
        start: 0,
        count: 0,
        total: 0,
        continuation: '',
      },
    };
  },
  computed: {
    showPager() {
      return this.api.total > maxRecords;
    },
    pagerLabels: () => ({
      first: 'Newest',
      previous: 'Newer',
      next: 'Older',
      last: 'Oldest',
    }),
    disablePreviousPagination() {
      return this.api.start === 0;
    },
    disableNextPagination() {
      return this.api.start + this.api.count >= this.api.total;
    },
  },
  created() {
    this.tableColumns = [
      {
        label: 'Name',
        size: 1,
        width: '100%',
      },
      { label: 'Type' },
      { label: 'Last Deployed' },
    ];
    return this.fetchData();
  },
  methods: {
    fetchData() {
      const timeoutId = setTimeout(() => {
        this.setStatus(
          'activity',
          'Loading User\'s Published Content...'
        );
      }, 300);

      return getPublishedContentForUser(this.userGuid, {
        count: maxRecords,
        start: this.api.start,
        cont: this.api.continuation,
      })
        .then(({ applications, count, total, continuation }) => {
          this.api.applications = applications;
          this.api.count = count;
          this.api.total = total;
          this.api.continuation = continuation;
          this.hideStatus();
        })
        .catch(err => {
          this.setStatus('error', safeAPIErrorMessage(err));
        })
        .finally(() => {
          clearTimeout(timeoutId);
        });
    },
    setStatus(type, message) {
      this.status.type = type;
      this.status.message = message;
    },
    hideStatus() {
      this.setStatus(null, null);
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
    fromGit,
    gotoPage(direction) {
      switch (direction) {
        case 'first':
          this.api.start = 0;
          break;
        case 'previous':
          this.api.start -= maxRecords;
          break;
        case 'next':
          this.api.start += maxRecords;
          break;
        case 'last':
          this.api.start = Math.floor(this.api.total / maxRecords) * maxRecords;
          break;
      }
      return this.fetchData();
    },
  },
};
</script>
