// Copyright (C) 2023 by Posit Software, PBC.

import { getServerSettings } from '@/api/serverSettings';

export const SERVER_SETTINGS_CLEAR = 'SERVER_SETTINGS_CLEAR';
export const SERVER_SETTINGS_ERROR = 'SERVER_SETTINGS_ERROR';
export const SERVER_SETTINGS_LOAD = 'SERVER_SETTINGS_LOAD';
export const SERVER_SETTINGS_SET = 'SERVER_SETTINGS_SET';
export const SERVER_SETTINGS_SET_VALUE = 'SERVER_SETTINGS_SET_VALUE';

export default {
  state: {
    loaded: false,
    error: null,
    settings: {},
  },
  mutations: {
    [SERVER_SETTINGS_SET](state, settings) {
      state.loaded = true;
      state.error = null;
      state.settings = settings;
    },
    [SERVER_SETTINGS_SET_VALUE](state, { key, checked }) {
      state.settings[key] = checked;
    },
    [SERVER_SETTINGS_ERROR](state, err) {
      state.loaded = false;
      state.error = err;
      state.settings = {};
    },

    [SERVER_SETTINGS_CLEAR](state) {
      state.loaded = false;
      state.error = null;
      state.settings = {};
    },
  },
  actions: {
    [SERVER_SETTINGS_LOAD]({ commit }, clearServerSettings = true) {
      return new Promise((resolve, reject) => {
        if (clearServerSettings) {
          commit(SERVER_SETTINGS_CLEAR);
        }

        getServerSettings()
          .then(settings => {
            commit(SERVER_SETTINGS_SET, settings);
            resolve(settings);
          })
          .catch(err => {
            commit(SERVER_SETTINGS_ERROR, err);
            reject(err);
          });
      });
    }
  }
};
