<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Dialog to create a group, it recieves a show prop to display itself,
  it emits a "close" and "created" events to the parent.
-->
<template>
  <RSModalForm
    :active="true"
    subject="Add Group"
    @close="close"
    @submit="create"
  >
    <template #content>
      <RSInputText
        ref="input"
        v-model.trim="form.groupName"
        label="Group Name"
        :message="groupNameError"
        name="cgd-name"
        data-automation="create-group__name"
      />
    </template>
    <template #controls>
      <RSButton
        label="Create Group"
        data-automation="new-group-create"
      />
    </template>
  </RSModalForm>
</template>

<script>
import { createGroup } from '@/api/groups';
import RSButton from '@/elements/RSButton';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { useVuelidate } from '@vuelidate/core';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'CreateGroupDialog',
  components: { RSButton, RSInputText, RSModalForm },
  emits: ['close', 'created'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: { groupName: '' },
    };
  },
  computed: {
    groupNameError() {
      return this.v$.form.groupName.$error
        ? 'Title is required and must be less than 4096 characters long.'
        : null;
    },
  },
  mounted() {
    this.$el.querySelector('#cgd-name').focus();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    close() {
      this.$emit('close');
      this.form.groupName = '';
      this.v$.form.$reset();
    },
    create() {
      // trigger validation before API submit and bail if form is not valid
      this.v$.form.groupName.$touch();
      if (this.v$.form.groupName.$invalid) {
        this.$nextTick(() => this.$refs.input.focusElement());
        // return resolved promise, as throwing would bubble up to action caller
        return Promise.resolve();
      }

      this.v$.form.$reset();

      this.clearStatusMessage();
      return createGroup(this.form.groupName)
        .then(group => {
          this.$emit('created', group);
          this.setInfoMessage({ message: `Group ${this.form.groupName} added.` });
        })
        .catch(e => {
          this.close();
          this.setErrorMessageFromAPI(e);
        });
    },
  },
  validations() {
    return {
      form: {
        groupName: {
          required: v => !!v,
          max: v => v.length < 4096,
        },
      },
    };
  },
};
</script>
