<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<script setup>
import { computed } from 'vue';

defineEmits(['clear', 'submit']);

const query = defineModel({ type: String });

const isPresent = computed(() => query.value !== '');
</script>

<!--
  The search box is a simple input field with a search icon and a clear button.
  One notable feature is that the clear button does not change focus on a mouse
  click.
-->
<template>
  <div class="search-box">
    <div class="search-box__icon" />
    <input
      v-model="query"
      type="text"
      aria-label="Search for content"
      placeholder="Search for content"
      class="search-box__input"
      data-automation="search-box__input"
      @keypress.enter.prevent="$emit('submit')"
    >
    <button
      v-if="isPresent"
      type="button"
      class="search-box__clear"
      data-automation="search-box__clear"
      aria-label="Clear search"
      title="Clear search"
      @mousedown.prevent
      @click="$emit('clear')"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.search-box {
  position: relative;
  padding: 0;
  margin: 0;

  &__input {
    border: 2px solid $color-medium-grey;
    border-radius: 6px;
    padding: 8px 45px;
    width: 100%;
    font-size: $rs-font-size-normal;
  }

  &__icon {
    position: absolute;
    pointer-events: none;
    top: 8px;
    left: 15px;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url('/images/elements/iconSearch.svg');
    background-size: 20px;
  }

  &__clear {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    color: $color-dark-grey;
    background-color: $color-light-grey;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url('/images/elements/close.svg');
    background-size: 24px;

    &:hover {
      cursor: pointer;
      background-color: $color-primary-light;
    }
  }
}
</style>
