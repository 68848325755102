<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :class="componentClass"
    role="button"
    tabindex="-1"
    @click.stop="handleSelection(true)"
    @keypress.stop="handleSelection(true)"
  >
    <div class="tags-group__main-tag">
      <div class="tags-group__main-tag-icon" />
      <input
        ref="mainInput"
        v-model.trim="mainTagLabel"
        :class="mainInputClass"
        aria-label="Tag Group Name"
        :data-automation="`tag-group-${mainTag.parentId}-${mainTag.id}-input`"
        @focus="handleSelection(false)"
        @blur="handleBlur($event, submitUpdateTag)"
        @keypress.enter="submitUpdateTag"
      >
      <span
        v-if="!isEditing"
        role="button"
        tabindex="-1"
        class="tags-group__main-tag-label"
        :data-automation="`tag-group-${mainTag.parentId}-${mainTag.id}-label`"
        @click="focusOnEditAttempt"
        @keypress.stop="focusOnEditAttempt"
      >
        {{ mainTagLabel }}
      </span>
    </div>
    <button
      v-if="isSelected"
      ref="delete"
      :aria-label="deleteTagLabel(mainTag.name)"
      class="tags-group__delete"
      :data-automation="`tag-group-${mainTag.parentId}-${mainTag.id}-delete-btn`"
      @blur="handleBlur"
      @click="submitDeleteIntent"
      @keypress.stop
    />
    <div
      class="tags-group__children"
    >
      <div
        v-for="tag in mainTag.children"
        :key="`tag-${tag.id}`"
        class="tags-group__sub-tag"
      >
        <TagsGroup
          v-if="tag.children.length"
          :main-tag="tag"
          @delete="submitDeleteTag(tag)"
        />
        <TagItem
          v-else
          :label="tag.name"
          :add-subtag-label="`Add subtag to ${tag.name}`"
          :delete-label="deleteTagLabel(tag.name)"
          @extend="submitNewTag({ ...$event, parent: tag })"
          @update="submitUpdateTag({ name: $event, tag })"
          @delete="submitDeleteTag(tag)"
        />
      </div>
      <AddItemInput
        ref="addSubtagInput"
        v-model="newTagName"
        :placeholder="`Add subtag to ${mainTag.name}`"
        @submit="submitNewTag"
        @blur="submitNewTag"
      />
    </div>
  </div>
</template>

<script>
import AddItemInput from '@/components/AddItemInput';
import {
  CREATE_NEW_TAG,
  RESET_FOCUS_FLAG,
  SET_DELETE_REQUEST,
  UPDATE_TAG,
} from '@/store/modules/adminTags';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { mapActions, mapMutations, mapState } from 'vuex';
import TagItem from './TagItem';
import TagSelectableBase from './TagSelectableBase';

const tagGroupClass = {
  base: 'tags-group',
  selected: 'tags-group--selected',
  mainInput: 'tags-group__main-tag-input',
  editingInput: 'tags-group__main-tag-input--editing',
};

export default {
  name: 'TagsGroup',
  components: {
    AddItemInput,
    TagItem,
  },
  extends: TagSelectableBase,
  props: {
    mainTag: {
      type: Object,
      required: true,
    },
  },
  emits: ['delete'],
  data() {
    return {
      mainTagLabel: this.mainTag.name,
      newTagName: '',
      lockSubmitTag: false,
    };
  },
  computed: {
    ...mapState({
      focusOnNewGroup: state => state.adminTags.focusOnNewGroup,
    }),
    componentClass() {
      const classMap = [tagGroupClass.base];
      if (this.isSelected) {
        classMap.push(tagGroupClass.selected);
      }
      return classMap;
    },
    mainInputClass() {
      const classMap = [tagGroupClass.mainInput];
      if (this.isEditing) {
        classMap.push(tagGroupClass.editingInput);
      }
      return classMap;
    },
  },
  created() {
    this.focusRefs = [
      'mainInput',
      'delete',
    ];
  },
  mounted() {
    // New tags group created?, focus on the add children input
    if (this.focusOnNewGroup) {
      this.$refs.addSubtagInput.focus();
      this.resetFocusFlag();
    }
  },
  methods: {
    ...mapMutations({
      resetFocusFlag: RESET_FOCUS_FLAG,
      requestDelete: SET_DELETE_REQUEST,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      createTag: CREATE_NEW_TAG,
      updateTag: UPDATE_TAG,
    }),
    // eslint-disable-next-line no-shadow
    submitNewTag({ name, parent, onBlur } = {}) {
      // Lock when a submission is already set, when @keypress resolution triggers @blur
      if (this.lockSubmitTag) {
        return;
      }
      this.lockSubmitTag = true;
      const payload = {
        name: name || this.newTagName,
        parentTag: parent || this.mainTag,
        discardFocus: onBlur,
      };
      return this.createTag(payload)
        .then(() => {
          this.newTagName = '';
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.lockSubmitTag = false;
        });
    },
    // eslint-disable-next-line no-shadow
    submitUpdateTag({ name, tag } = {}) {
      const payload = {
        name: name || this.mainTagLabel,
        currentTag: tag || this.mainTag,
      };
      return this.updateTag(payload)
        .catch(response => {
          this.mainTagLabel = payload.currentTag.name;
          return this.setErrorMessageFromAPI(response);
        });
    },
    deleteTagLabel(tagName) {
      return `Delete tag \"${tagName}\"`;
    },
    // eslint-disable-next-line no-shadow
    submitDeleteTag({ id, name, version } = {}) {
      const payload = {
        id,
        name,
        version,
        parentTag: this.mainTag,
      };
      this.requestDelete(payload);
    },
    submitDeleteIntent() {
      this.$emit('delete');
    },
  },
};
</script>
