<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Renders the routable Documentation view container
-->
<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="flex">
        <h1
          ref="title"
          class="sectionTitle focusedTitle"
          tabindex="-1"
        >
          Documentation
        </h1>
      </div>
      <div class="section">
        Posit Connect is a publishing platform for the work your teams create in
        R and Python. Share Shiny applications, R Markdown reports, Plumber and
        Flask APIs, Dash and Streamlit applications, Quarto projects, dashboards,
        plots, Jupyter Notebooks, and more in one convenient place. Use
        push-button publishing from the RStudio IDE, scheduled execution of
        reports, and flexible security policies to bring the power of data science
        to your entire enterprise.
      </div>

      <DocumentationViewItems />

      <ConnectLogoAnchor />

      <About />
    </div>
  </div>
</template>

<script>
import ConnectLogoAnchor from '@/components/ConnectLogoAnchor.vue';
import About from '@/views/documentation/About';
import DocumentationViewItems from '@/views/documentation/DocumentationViewItems';

export default {
  name: 'DocumentationView',
  components: {
    DocumentationViewItems,
    ConnectLogoAnchor,
    About,
  },
  mounted() {
    this.$refs.title.focus();
  }
};
</script>
