<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div>
    <table
      class="vertical-space local-about-table"
      data-automation="documentation-about-local__table"
    >
      <tr>
        <td class="emphasize-no-wrap right-space">
          Product:
        </td>
        <td>
          Posit Connect
        </td>
      </tr>
      <tr v-if="version">
        <td class="emphasize-no-wrap right-space">
          Version:
        </td>
        <td>
          {{ version }}
        </td>
      </tr>
      <tr v-if="build">
        <td class="emphasize-no-wrap right-space">
          Build:
        </td>
        <td>
          {{ build }}
        </td>
      </tr>
      <template v-if="hasLocalEnvironment">
        <tr>
          <td class="emphasize-no-wrap right-space">
            R Versions:
          </td>
          <td class="r-versions">
            {{ rVersions }}
          </td>
        </tr>
        <tr>
          <td class="emphasize-no-wrap right-space">
            Python Versions:
          </td>
          <td class="python-versions">
            {{ pythonVersions }}
          </td>
        </tr>
        <tr>
          <td class="emphasize-no-wrap right-space">
            Quarto Versions:
          </td>
          <td class="quarto-versions">
            {{ quartoVersions }}
          </td>
        </tr>
        <tr>
          <td class="emphasize-no-wrap right-space">
            TensorFlow Versions:
          </td>
          <td class="tensorflow-versions">
            {{ tensorflowVersions }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>

export default {
  name: 'AboutLocal',
  props: {
    about: {
      type: String,
      required: true,
    },
    build: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
    environments: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasLocalEnvironment() {
      return this.environments['Local:Local'] !== undefined;
    },
    environment() {
      return this.environments['Local:Local'] || {};
    },
    rVersions() {
      return this.buildVersionString(this.environment.rVersions);
    },
    pythonVersions() {
      return this.buildVersionString(this.environment.pythonVersions);
    },
    quartoVersions() {
      return this.buildVersionString(this.environment.quartoVersions);
    },
    tensorflowVersions() {
      return this.buildVersionString(this.environment.tensorflowVersions);
    },
  },
  methods: {
    buildVersionString(versions) {
      if (versions === undefined) {
        // Likely to be unreachable with real data.
        return '(unknown)';
      }

      if (versions.length === 0) {
        return '(none)';
      }

      return versions.join(', ');
    },
  },
};
</script>
