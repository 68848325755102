<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<!--

This is just a wrapper component for the Flatpickr Vue version
(https://github.com/ankurk91/vue-flatpickr-component) of its
datepicker.

-->
<script>
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'DatePicker',
  extends: flatPickr,
  props: {
    config: {
      type: Object,
      default: () => ({
        altInput: true,
        altInputClass: 'rs-datepicker',
        allowInput: true,
        defaultDate: new Date(Date.now()),
        dateFormat: 'F j, Y',
      }),
    },
  },
};

</script>

<style lang="scss">
@import 'flatpickr/dist/flatpickr.css';
@import 'Styles/shared/_colors';

.rs-datepicker {
  width: 100%;
}

.flatpickr-calendar {
  box-shadow: 2px 2px 5px $color-medium-grey;
}
</style>
