<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div
      v-if="currentStatus.activity.showActivity"
      class="statusMessage activity showing"
    >
      <div class="message">
        <div class="messageSpinnerContainer">
          <Spinner />
        </div>
        {{ currentStatus.activity.message }}
      </div>
    </div>

    <div
      v-if="currentStatus.status.showErrorOrInfo"
      role="button"
      tabindex="-1"
      :class="currentStatus.status.cssClass"
      @click="closeMessage"
      @keyup.enter="closeMessage"
      @keyup.space="closeMessage"
    >
      <div
        class="message"
        data-automation="status-message-info-text"
        role="alert"
        aria-live="assertive"
        v-html="currentStatus.status.message"
      />
      <button
        id="messageClose"
        aria-label="Close"
        data-automation="status-message-info-close"
      />
    </div>

    <div
      v-if="currentStatus.status.showWarning"
      role="button"
      tabindex="-1"
      :class="currentStatus.status.cssClass"
      @click="closeMessage"
      @keyup.enter="closeMessage"
      @keyup.space="closeMessage"
    >
      <div
        class="message"
        data-automation="status-message-warning-text"
        role="alert"
        aria-live="assertive"
      >
        {{ currentStatus.status.message }}
      </div>
      <button
        id="messageClose"
        aria-label="Close"
        data-automation="status-message-warning-close"
      />
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import { StatusTypes, CLEAR_STATUS_MESSAGE } from '@/store/modules/messages';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'StatusMessage',
  components: {
    Spinner
  },
  computed: {
    ...mapState({
      statusMsg: state => state.messages.statusMessage,
      activityMessage: state => state.messages.activityMessage,
    }),
    hasActivityMessage() {
      return (
        !!this.activityMessage.message &&
        this.activityMessage.page === this.$route.name
      );
    },
    currentStatus() {
      const activity = { showActivity: this.hasActivityMessage };
      if (this.hasActivityMessage) {
        activity.message = this.activityMessage.message;
      }
      // then get the other status messages
      const { message, statusType } = this.statusMsg;
      let statusOptions = {
        showErrorOrInfo: false,
        showWarning: false
      };

      // Define response based on message type
      switch (statusType) {
        case StatusTypes.ERROR_MESSAGE:
          statusOptions = {
            cssClass: 'statusMessage error showing',
            message,
            showErrorOrInfo: true
          };
          break;
        case StatusTypes.INFO_MESSAGE:
          statusOptions = {
            cssClass: 'statusMessage info showing',
            message,
            showErrorOrInfo: true
          };
          break;
        case StatusTypes.WARNING_MESSAGE:
          statusOptions = {
            cssClass: 'statusMessage warning showing',
            message,
            showWarning: true
          };
          break;
      }

      return {
        activity,
        status: statusOptions
      };
    },
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
    }),
    closeMessage() {
      this.clearStatusMessage();
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.statusMessage {
  position: relative;
  max-height: 0px;
  overflow: hidden;

  @include normal-transition-duration();
  @include transition-property(max-height, margin);

  .message {
    text-align: center;
    border-top: 1px dotted $color-light-grey-4;
    line-height: 26px;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    padding: 4px 45px;

    a {
      color: #fff;
    }
  }

  &.showing {
    max-height: 100px;
  }

  &.activity {
    @include transition-delay(0.5s);
    .message {
      background-color: $color-light-grey;
      color: $color-dark-grey-3;

      .messageSpinnerContainer {
        display: inline-block;
      }
    }
  }

  &.warning {
    .message {
      background-color: #f9f5ed;
      color: #93691a;
    }
  }

  &.error {
    .message {
      background-color: #ffeded;
      color: $color-error;
    }
  }

  &.info {
    .message {
      background-color: lighten($color-alert-info, 15%);
      color: darken($color-posit-green, 15%);
    }
  }

  #messageClose {
    position: absolute;
    right: .5em;
    top: 2px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .messageSpinnerContainer {
    display: none;
    width: 25px;
    height: 25px;
    margin: -5px 5px -5px 0;
  }
}

</style>
