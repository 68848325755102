<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <fieldset>
    <RSInputSelect
      ref="branchSelect"
      :model-value="modelValue"
      label="Select a branch:"
      :options="branchNames"
      name="git-branch-name"
      data-automation="git-branch-name"
      @change="newValue => $emit('update:modelValue', newValue)"
    />
  </fieldset>
</template>

<script>
import RSInputSelect from '@/elements/RSInputSelect';

export default {
  name: 'SelectBranch',
  components: {
    RSInputSelect,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    branchNames: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    this.$nextTick(() => this.$refs.branchSelect.focusElement());
  },
};
</script>
