<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--

  DatePicker provides a date input along with a calendar control (on focus) for visually
  selecting a date.

-->

<template>
  <div
    :class="{ active }"
    :disabled="disabled ? 'disabled' : null"
    class="date-input rsc-date-input"
  >
    <flat-pickr
      ref="input"
      v-model="startDate"
      class="date-input__input"
      :config="pickerConfigs"
      @on-open="activate"
      @on-close="deactivate"
      @on-change="picked"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import flatPickr from 'vue-flatpickr-component';

const MIN_START_DATE = '01/01/2010';

export default {
  name: 'DateInput',
  components: {
    flatPickr
  },
  props: {
    // date is expected to be a `dayjs` wrapped Date object if provided
    date: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['focus', 'date', 'change'],
  data() {
    return {
      active: false,
      startDate: new Date(this.date),
    };
  },
  computed: {
    pickerDate() {
      if (!this.date) {
        // Default to today if no date is provided
        return dayjs().toDate();
      }

      return this.date.toDate();
    },
    pickerConfigs() {
      return {
        allowInput: !this.disabled,
        altFormat: 'M j, Y',
        altInput: true,
        altInputClass: 'date-input__input',
        defaultDate: this.pickerDate,
        parseDate: this.parse,
        minDate: MIN_START_DATE
      };
    }
  },
  watch: {
    date: {
      handler(newDate) {
        if (newDate.valueOf() !== new Date(this.startDate).getTime()) {
          this.startDate = newDate.toDate();
        }
      },
    }
  },
  mounted() {
    if (this.disabled) {
      document.querySelector('.date-input__input')._flatpickr._input.disabled = true;
    }
  },
  methods: {
    parse(datestr) {
      const date = dayjs(datestr);
      if (date < dayjs(MIN_START_DATE)) {
        return new Date(MIN_START_DATE);
      }
      return date.toDate();
    },
    activate() {
      document.querySelectorAll('.date-input__input')[1].select();
      this.active = true;
      this.$emit('focus', 'date');
    },
    deactivate() {
      this.$nextTick(() => { this.active = false; });
    },
    picked(date) {
      this.update(dayjs(date));
    },
    update(date) {
      if (this.active) {
        this.$emit('change', date);
      }
    },
  }
};
</script>

<style lang="scss">
.date-input {
  position: relative;
  display: inline-block;

  .date-input__input {
    /* rsc-row input has so much specificity... */
    width: 120px!important;
  }
}
</style>
