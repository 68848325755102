<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
    DeprecatedSettings

    Renders the deprecated settings view.
  -->
<template>
  <div
    v-if="display"
    class="broadcastMessage"
  >
    <span class="deprecated-settings-message">
      Your configuration is using deprecated settings.
      Check your server logs and adjust the configuration as suggested.
    </span>
    <button
      class="messageClose"
      aria-label="Close message"
      @click="dismiss"
    />
  </div>
</template>

<script>
import {
  SERVER_NOTICES_DEPRECATED_CLOSE
} from '@/store/modules/serverNotices';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'DeprecatedSettings',
  props: {
    server: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState({
      active: state => state.serverNotices.deprecated,
    }),
    enabled() {
      // The server prevents display of this information by including
      // deprecated setting information and indicating that the UI warning is
      // enabled only for publishers and administrators.
      return this.server.deprecatedSettingsUiWarning === true;
    },
    deprecatedSettings() {
      return this.server.deprecatedSettings === true;
    },
    display() {
      return this.enabled && this.active && this.deprecatedSettings;
    },
  },
  methods: {
    ...mapMutations({
      dismiss: SERVER_NOTICES_DEPRECATED_CLOSE,
    }),
  },
};
</script>
