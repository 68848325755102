<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    v-if="loaded"
    class="band"
  >
    <div class="bandContent mainPage">
      <div class="menu">
        <div class="menuItems">
          <router-link
            :to="{ name: 'people.users.profile', params: { guid: currentUser.guid } }"
            class="menuItem"
            role="button"
          >
            Profile
          </router-link>
          <router-link
            :to="{ name: 'people.users.api_keys', params: { guid: currentUser.guid } }"
            class="menuItem active"
            role="button"
            :aria-current="true"
          >
            API Keys
          </router-link>
        </div>
      </div>

      <div class="majorMinorColumnsContainer">
        <div class="majorColumn">
          <div class="sectionTitle flex">
            <h1
              class="view-title"
              data-automation="api-keys-title"
            >
              API Keys
            </h1>
            <div class="actionBar inline showTitles">
              <BaseButton
                title="New API Key"
                label="New API Key"
                aria-label="Add new API key"
                class="action new"
                data-automation="add-new-api-key"
                @clicked.prevent="toggleCreateKeyModal"
              />
            </div>
          </div>

          <KeysTable
            v-if="hasKeys"
            :keys="apiKeys"
            @delete-intention="onDeleteRequest"
          />
          <p
            v-else
            class="no-keys-msg"
          >
            You don't have any API keys. To create one, click the 'New API Key' button above.
          </p>
        </div>
        <div class="minorColumn">
          <div
            data-automation="api-keys__about__container"
            class="section"
          >
            <h2 class="sectionTitle view-subtitle">
              About API Keys
            </h2>
            <div class="sectionBlurb">
              <p>
                <span class="emphasize">
                  Treat your API Key like you treat your password.
                  Anyone with your API Key can impersonate you.
                </span>
              </p>
              <p>
                API keys enable you to send requests to {{ systemDisplayName }} that have
                all the same privileges that you have when logged in in a web
                browser. For example, you could use these keys to automate
                requests to hosted content that require authentication.
              </p>
              <p>
                See the
                <a
                  :href="linkToApiKeysDocs"
                  target="_blank"
                >
                  API Keys documentation
                </a>
                for more details.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteKey
      v-if="showDeleteModal"
      :api-key="deleteKeyTarget"
      @deleted="onKeyDeleted"
      @close="toggleDeleteKeyModal"
    />
    <CreateKey
      v-if="showCreateModal"
      @created="onKeyCreated"
      @close="toggleCreateKeyModal"
    />
    <NewKeyConfirmation
      v-if="showNewKeyConfirmation"
      :api-key="newKey"
      @close="clearNewKeyConfirmation"
    />
  </div>
</template>

<script>
import { getCurrentUserAPIKeys } from '@/api/users';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { docsPath } from '@/utils/paths';
import { mapActions, mapMutations, mapState } from 'vuex';

import BaseButton from '@/components/BaseButton';
import CreateKey from '@/views/users/apikeys/CreateKey';
import DeleteKey from '@/views/users/apikeys/DeleteKey';
import KeysTable from '@/views/users/apikeys/KeysTable';
import NewKeyConfirmation from '@/views/users/apikeys/NewKeyConfirmation';

export default {
  name: 'UserKeysView',
  components: {
    BaseButton,
    KeysTable,
    CreateKey,
    DeleteKey,
    NewKeyConfirmation,
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      newKey: null,
      deleteKeyTarget: null,
      showDeleteModal: false,
      showCreateModal: false,
      showNewKeyConfirmation: false,
      apiKeys: [],
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      systemDisplayName: state => state.server.settings.systemDisplayName,
    }),
    hasKeys() {
      return this.apiKeys.length > 0;
    },
    linkToApiKeysDocs() {
      return docsPath('/user/api-keys');
    },
  },
  created() {
    this.pullUserKeys();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    pullUserKeys() {
      return getCurrentUserAPIKeys()
        .then(apiKeys => {
          if (this.currentUser.guid === this.guid) {
            this.apiKeys = apiKeys;
            this.loaded = true;
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    onDeleteRequest(key) {
      this.deleteKeyTarget = key;
      this.toggleDeleteKeyModal();
    },
    toggleDeleteKeyModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    toggleCreateKeyModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    onKeyDeleted() {
      this.pullUserKeys();
      this.toggleDeleteKeyModal();
      this.setInfoMessage({
        message: 'API Key Deleted.',
      });
    },
    onKeyCreated(newKey) {
      this.pullUserKeys();
      this.toggleCreateKeyModal();
      this.newKey = newKey;
      this.showNewKeyConfirmation = true;
    },
    clearNewKeyConfirmation() {
      this.showNewKeyConfirmation = false;
      this.newKey = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-title {
  font-size: 1.5rem;
}
.view-subtitle {
  font-size: 1.25rem;
}
.no-keys-msg {
  padding: 2rem;
  text-align: center;
}
.sectionBlurb p {
  margin-bottom: 1rem;
}
</style>
