<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <pre
    ref="output"
    class="log-overlay__output"
    tabindex="0"
    @scroll="onScroll"
  >
    <!-- eslint-disable vue/no-v-for-template-key -->
    <template
      v-for="(entry, i) in searchedEntries"
      :key="i"
    >
      <!-- eslint-enable vue/no-v-for-template-key -->
      <time
        class="timestamp"
        :title="entry.timestamp"
      >
        {{ `${entry.localTimestamp}: ` }}
      </time>
      <span
        :class="['content', entry.source, wrapLongLines ? 'wrap' : '']"
        v-html="entry.data"
      />
    </template>
</pre>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import stripansi from 'strip-ansi';
import { getJobLog, tailJobPath } from '@/api/jobs';

dayjs.extend(utc);

const escapeHtml = unsafe => {
  return unsafe
    .replace(/&/g, `&amp;`)
    .replace(/</g, `&lt;`)
    .replace(/>/g, `&gt;`)
    .replace(/"/g, `&quot;`)
    .replace(/'/g, `&#039;`);
};

export default {
  name: 'LogOutput',
  props: {
    app: {
      type: Object,
      required: true,
    },
    job: {
      type: Object,
      required: true,
    },
    searchText: {
      type: String,
      default: null,
    },
    searchCurrent: {
      type: Number,
      default: 0,
    },
    wrapLongLines: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['matches', 'reset'],
  data() {
    return {
      tail: null,
      entries: [],
      searchedEntries: [],
      following: true,
    };
  },
  computed: {
    sanitizedSearchText() {
      if (!this.searchText) {
        return null;
      }
      return this.searchText.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
    },
    searchMatches() {
      return this.searchedEntries.reduce(
        (accum, entry) => accum + entry.matches,
        0,
      );
    },
  },
  watch: {
    job: {
      immediate: true,
      handler(job) {
        const { guid } = this.app;
        const { key } = job;

        if (this.tail) {
          this.tail.removeEventListener('entry', this.appendLogEntry);
          this.tail.close();
          this.tail = null;
        }

        getJobLog(guid, key).then(entries => {
          this.entries = entries;
          this.searchedEntries = this.entries.map(entry => this.processEntry(entry));
          this.$nextTick(() => this.scrollToLatest());
        })
          .then(() => {
            this.tail = new EventSource(tailJobPath(guid, key));
            this.tail.addEventListener('entry', this.appendLogEntry);
          });
      }
    },
    searchText() {
      this.searchedEntries = this.entries.map(entry => this.processEntry(entry));
      this.$nextTick(() => this.$emit('reset'));
    },
    searchMatches(value) {
      this.$emit('matches', value);
    },
    searchCurrent(newVal) {
      this.updateCurrent(newVal);
    },
  },
  unmounted() {
    if (this.tail) {
      this.tail.removeEventListener('entry', this.appendLogEntry);
      this.tail.close();
      this.tail = null;
    }
  },
  methods: {
    updateCurrent(value) {
      this.removeCurrent();

      let current;

      if (value === 0) {
        current = this.$el.getElementsByClassName('highlighted')[0];
      } else {
        current = this.$el.getElementsByClassName('highlighted')[value - 1];
      }

      if (current) {
        current.classList.add('current');
        this.following = false;
        this.$nextTick(() => this.scrollToCurrent(current));
      }
    },
    removeCurrent() {
      const all = this.$el.getElementsByClassName('current');

      for (const el of all) {
        el.classList.remove('current');
      }
    },
    // processEntry augments each log entry with search-related info as well as
    // localized timestamps.
    processEntry(e) {
      const entry = {
        ...e,
        matches: 0,
      };

      entry.data = entry.data.replaceAll('\r', ' ');
      entry.data = stripansi(entry.data);
      entry.data = escapeHtml(entry.data);

      if (this.searchText) {
        entry.data = entry.data.replace(new RegExp(this.sanitizedSearchText, 'gi'), match => {
          entry.matches++;
          return `<span class="highlighted">${match}</span>`;
        });
      }

      entry.localTimestamp = dayjs
        .utc(entry.timestamp)
        .local()
        .format('YYYY/MM/DD h:mm:ss A');

      return entry;
    },
    appendLogEntry(e) {
      const entry = JSON.parse(e.data);
      this.entries.push(entry);
      this.searchedEntries.push(this.processEntry(entry));
      this.$nextTick(() => this.scrollToLatest());
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.following = true;
      } else {
        this.following = false;
      }
    },
    scrollToCurrent(el) {
      this.following = false;
      el.scrollIntoView();
    },
    scrollToLatest() {
      if (this.$refs.output && this.following) {
        this.$refs.output.scrollTop = this.$refs.output.scrollHeight;
      }
    },
  }
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.log-overlay__output {
  @include control-visible-focus;
  padding: 0 1rem;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: auto;
  height: 0;
  margin: 8px;
  background-color: $color-white;
  white-space: normal;
  display: grid;
  grid-template-columns: 13rem 1fr;
  color: black;

  >.timestamp {
    color: #737373;
    font-size: 13px;
    font-style: normal;
    padding-right: 1rem;
  }

  >.content {
    white-space: pre;
    color: green;

    &.stdout {
      color: $color-dark-grey-3;
    }

    &.stderr {
      color: $color-error;
      font-style: italic;
    }

    &.wrap {
      white-space: pre-wrap;
    }

    >.highlighted {
      background-color: $color-highlight-inactive;
      border-bottom: 1px solid $color-dark-grey;
      border-radius: 2px;
      &.current {
        background-color:$color-highlight-active;
        border-bottom: 2px solid $color-dark-grey-3;
      }
    }
  }
}
</style>
