<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__install-rsconnect wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Get the Connect Jupyter Plugin
      </StepHeading>
      <p>
        To publish a Jupyter notebook to {{ systemDisplayName }}, first install
        the rsconnect-jupyter plugin in your Jupyter environment.
      </p>
      <p>
        <a
          ref="learnMoreLink"
          href="https://docs.posit.co/rsconnect-jupyter/"
          target="_blank"
        >
          Learn more about the rsconnnect-jupyter plugin
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import { mapState } from 'vuex';
import PanelMixin from '../PanelMixin';

export default {
  name: 'InstallRSConnectJupyter',
  components: { StepHeading },
  mixins: [PanelMixin],
  computed: {
    ...mapState({
      systemDisplayName: state => state.server.settings.systemDisplayName
    })
  },
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__install-rsconnect {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>
