<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="tags-help">
    <ul class="tags-help__overview">
      <li>
        Use tags to organize your content to make it easy for your users to find
        the content they're interested in.
      </li>
      <li>
        As an administrator, you define the tagging schema that your data
        scientists then use to categorize the content they publish.
      </li>
      <li>
        To get started, press the <strong>Create Your Schema</strong> button above and to the right.
      </li>
    </ul>

    <h2 class="tags-help__subtitle sectionSubtitle">
      Defining Your Schema
    </h2>
    <p class="tags-help__description">
      To create a tagging schema for the content in your organization, you
      define one or more tag categories. Within each category, you define tags,
      which can be nested any number of levels deep. Here's an example schema
      for a marine mammal research organization that has four tag categories:
      Research Area, Project Status, Grant and Species.
    </p>

    <div class="tags-help__schema">
      <div
        v-for="category in sampleSchema"
        :key="category.id"
        class="tags-category"
      >
        <div class="tags-category__name">
          <h2 class="tags-category__name-label">
            {{ category.name }}
          </h2>
        </div>
        <div
          v-for="tag in category.children"
          :key="tag.id"
          class="tags-category__child"
        >
          <div
            v-if="tag.children.length"
            class="tags-group"
          >
            <div class="tags-group__main-tag">
              <div class="tags-group__main-tag-icon" />
              <span class="tags-group__main-tag-label">
                {{ tag.name }}
              </span>
            </div>
            <div class="tags-group__children">
              <div
                v-for="groupTag in tag.children"
                :key="`tag-${groupTag.id}`"
                class="tags-group__sub-tag"
              >
                <div class="tag-record">
                  <div class="tag-record__label">
                    <div class="tag-record__icon" />
                    <span class="tag-record__label-text">
                      {{ groupTag.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="tag-record"
          >
            <div class="tag-record__label">
              <div class="tag-record__icon" />
              <span class="tag-record__label-text">
                {{ tag.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="tags-help__schema-stamp" />
    </div>
  </div>
</template>

<script>
import { sampleSchema } from './sampleSchema';

export default {
  name: 'TagsGettingStarted',
  created() {
    this.sampleSchema = sampleSchema;
  },
};
</script>

<style scoped lang="scss">
.sectionSubtitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  margin: 4px 0 10px 0;
}

</style>
