<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div
    data-automation="environment-table-row-summary"
    class="summary-container"
    :tabindex="dialogMode ? 0 : -1"
    @click.prevent="$emit('click')"
    @keyup.enter.stop.prevent="$emit('click')"
  >
    <div
      ref="environmentTitle"
      class="environment"
      :class="{ 'emphasized': accordionOpen }"
    >
      <div class="environment__title">
        <div
          class="environment__title__value"
          data-automation="environment-title"
          :title="environmentTitleTooltipText"
        >
          {{ environment.title }}
        </div>
        <div
          v-if="noInstallationsDefined"
          class="warning-tag"
          :title="`This environment will not be used by the system
            until an administrator has defined installations.`"
          data-automation="incomplete-warning-tag"
        >
          Incomplete
        </div>
      </div>
      <div
        v-if="!accordionOpen && showName"
        class="environment__name"
        data-automation="environment-name"
      >
        {{ environment.name }}
      </div>
    </div>
    <div
      data-automation="python-installs"
      :class="dialogMode ? 'installs__small' : 'installs'"
      :title="pythonInstallsTooltipText"
    >
      <span
        v-if="!accordionOpen"
        data-automation="python-install-string"
      >
        {{ pythonInstallationsString }}
      </span>
      <span v-if="accordionOpen">
        &nbsp;
      </span>
    </div>
    <div
      data-automation="quarto-installs"
      :class="dialogMode ? 'installs__small' : 'installs'"
      :title="quartoInstallsTooltipText"
    >
      <span
        v-if="!accordionOpen"
        data-automation="quarto-install-string"
      >
        {{ quartoInstallationsString }}
      </span>
      <span v-if="accordionOpen">
        &nbsp;
      </span>
    </div>
    <div
      data-automation="r-installs"
      :class="dialogMode ? 'installs__small' : 'installs'"
      :title="rInstallsTooltipText"
    >
      <span
        v-if="!accordionOpen"
        data-automation="r-install-string"
      >
        {{ rInstallationsString }}
      </span>
      <span v-if="accordionOpen">
        &nbsp;
      </span>
    </div>
    <div
      data-automation="tensorflow-installs"
      :class="dialogMode ? 'installs__small' : 'installs'"
      :title="tensorflowInstallsTooltipText"
    >
      <span
        v-if="!accordionOpen"
        data-automation="tensorflow-install-string"
      >
        {{ tensorflowInstallationsString }}
      </span>
      <span v-if="accordionOpen">
        &nbsp;
      </span>
    </div>
    <div
      v-if="!readonly"
      class="action__container"
    >
      <div
        data-automation="environment-actions"
        class="action__row"
      >
        <button
          class="action-button"
          data-automation="edit-action"
          @click="onEdit"
        >
          <img
            src="/images/itemActionEdit.svg"
            alt="edit environment"
            class="editActionButton action__environment"
            title="Edit environment"
          >
        </button>
        <button
          class="action-button"
          data-automation="delete-action"
          @click="onDelete"
        >
          <img
            src="/images/itemActionDelete.svg"
            alt="delete environment"
            class="deleteActionButton action__environment"
            title="Delete environment"
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import pluralize from '@/utils/pluralize';
export default {
  name: 'EnvironmentsTableRowSummary',
  props: {
    environment: {
      type: Object,
      required: true,
    },
    accordionOpen: {
      type: Boolean,
      required: true,
    },
    activeSort: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dialogMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'delete', 'edit'],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    showName() {
      return this.environment.title !== this.environment.name;
    },
    noInstallationsDefined() {
      return (
        this.quartoInstallations.length === 0 &&
        this.pythonInstallations.length === 0 &&
        this.rInstallations.length === 0 &&
        this.tensorflowInstallations.length === 0
      );
    },
    quartoInstallationsString() {
      if (this.activeSort.key === 'Quarto' && this.activeSort.direction === 'desc') {
        if (this.environment.installationStrings.Quarto.desc.length > 0) {
          return this.environment.installationStrings.Quarto.desc;
        }
        return 'None';
      }
      if (this.environment.installationStrings.Quarto.asc.length > 0) {
        return this.environment.installationStrings.Quarto.asc;
      }
      return 'None';
    },
    quartoInstallations() {
      return this.environment.installations.filter(install => install.type === 'Quarto');
    },
    pythonInstallationsString() {
      if (this.activeSort.key === 'Python' && this.activeSort.direction === 'desc') {
        if (this.environment.installationStrings.Python.desc.length > 0) {
          return this.environment.installationStrings.Python.desc;
        }
        return 'None';
      }
      if (this.environment.installationStrings.Python.asc.length > 0) {
        return this.environment.installationStrings.Python.asc;
      }
      return 'None';
    },
    pythonInstallations() {
      return this.environment.installations.filter(install => install.type === 'Python');
    },
    rInstallationsString() {
      if (this.activeSort.key === 'R' && this.activeSort.direction === 'desc') {
        if (this.environment.installationStrings.R.desc.length > 0) {
          return this.environment.installationStrings.R.desc;
        }
        return 'None';
      }
      if (this.environment.installationStrings.R.asc.length > 0) {
        return this.environment.installationStrings.R.asc;
      }
      return 'None';
    },
    rInstallations() {
      return this.environment.installations.filter(install => install.type === 'R');
    },
    tensorflowInstallationsString() {
      if (this.activeSort.key === 'TensorFlow' && this.activeSort.direction === 'desc') {
        if (this.environment.installationStrings.TensorFlow.desc.length > 0) {
          return this.environment.installationStrings.TensorFlow.desc;
        }
        return 'None';
      }
      if (this.environment.installationStrings.TensorFlow.asc.length > 0) {
        return this.environment.installationStrings.TensorFlow.asc;
      }
      return 'None';
    },
    tensorflowInstallations() {
      return this.environment.installations.filter(install => install.type === 'TensorFlow');
    },
    environmentTitleTooltipText() {
      return `${this.environment.title} uses the container image ${this.environment.name}.`;
    },
    pythonInstallsTooltipText() {
      if (this.pythonInstallations.length === 0) {
        return 'No Python versions configured';
      }
      return `Configured Python ${pluralize(this.pythonInstallations.length, 'version', 'versions')}: ${this.pythonInstallationsString}`;
    },
    quartoInstallsTooltipText() {
      if (this.quartoInstallations.length === 0) {
        return 'No Quarto versions configured';
      }
      return `Configured Quarto ${pluralize(this.quartoInstallations.length, 'version', 'versions')}: ${this.quartoInstallationsString}`;
    },
    rInstallsTooltipText() {
      if (this.rInstallations.length === 0) {
        return 'No R versions configured';
      }
      return `Configured R ${pluralize(this.rInstallations.length, 'version', 'versions')}: ${this.rInstallationsString}`;
    },
    tensorflowInstallsTooltipText() {
      if (this.tensorflowInstallations.length === 0) {
        return 'No TensorFlow versions configured';
      }
      return `Configured TensorFlow ${pluralize(this.tensorflowInstallations.length, 'version', 'versions')}: ${this.tensorflowInstallationsString}`;
    },
  },
  methods: {
    onEdit() {
      this.$emit('edit');
    },
    onDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.emphasized {
  font-weight: bold;
  font-size: medium;
}
.summary-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
  &:focus-visible {
    outline: none;
    .environment__title__value {
      @include control-focus;
      padding: 0 2px;
      margin-top: 2px;
    }
  }
}
.environment {
  flex: 1 0 10rem;
  padding-left: 1rem;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
  text-overflow: ellipsis;
  line-height: 2rem;
  font-size: 0.9rem;
  height: 3rem;
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    &__value {
      font-size: 0.9rem;
      line-height: 2rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__name {
    font-size: smaller;
    line-height: 0.9rem;
    color: light-gray;
    max-width: 95%;
    overflow-x: hidden;
    overflow-y: visible;
    text-overflow: ellipsis;
  }
}

.installs {
  flex: 0 0 8rem;
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2rem;

  &__small {
    flex: 0 0 6rem;
    padding-left: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2rem;
  }
}

.action-button {
  padding: 0;
  background-color: transparent;
  margin-right: 2px;
  @include control-visible-focus;
  &:hover {
    background-color: $color-button-background-hover;
  }
}

.action {
  &__container {
    flex: 0 0 4rem;
    line-height: 2rem;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__environment {
    width: 29px;
    filter: brightness(0) saturate(100%);
    cursor: pointer;
  }
}

.warning-tag {
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #f9edd4;
  background-color: #f9f5ed;
  color: #93691a;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  line-height: 26px;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

</style>
