<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Dialog to rename a group, extending CreateGroupDialog as it behaves fairly similar.
-->
<template>
  <RSModalForm
    :active="true"
    subject="Rename Group"
    @close="close"
    @submit="rename"
  >
    <template #content>
      <RSInputText
        ref="input"
        v-model.trim="form.groupName"
        :label="`Enter the new name for ${group.name}.`"
        :message="groupNameError"
        name="cgd-name"
        data-automation="rename-group__name"
      />
    </template>
    <template #controls>
      <RSButton
        label="Rename Group"
        data-automation="rename-group"
      />
    </template>
  </RSModalForm>
</template>

<script>
import { renameGroup } from '@/api/groups';
import RSButton from '@/elements/RSButton';
import RSInputText from '@/elements/RSInputText';
import RSModalForm from '@/elements/RSModalForm';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import CreateGroupDialog from '@/views/groups/CreateGroupDialog';
import { useVuelidate } from '@vuelidate/core';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'RenameGroupDialog',
  components: { RSButton, RSInputText, RSModalForm },
  extends: CreateGroupDialog,
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  emits: ['rename'],
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.$refs.input.focusElement();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    rename() {
      // trigger validation before API submit and bail if form is not valid
      this.v$.form.groupName.$touch();
      if (this.v$.form.groupName.$invalid) {
        // return resolved promise, as throwing would bubble up to action caller
        return Promise.resolve();
      }

      this.v$.form.$reset();

      this.clearStatusMessage();
      return renameGroup(this.group.guid, this.form.groupName)
        .then(group => {
          this.$emit('rename', group);
          this.setInfoMessage({ message: `Group successfully renamed as ${this.form.groupName}.` });
        })
        .catch(e => {
          this.close();
          this.setErrorMessageFromAPI(e);
        });
    },
  },
};
</script>

<style lang="scss">
label[for="cgd-name"] {
  width: 100%;
}
</style>
