<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__install-quarto wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Get Quarto
      </StepHeading>
      <p>
        A Quarto installation is needed to render and publish Quarto content.
      </p>
      <p>
        RStudio and Posit Workbench users have Quarto available by default in
        the RStudio UI, at the R console, and in the integrated terminal.
      </p>
      <p>
        Please
        <a
          ref="instructionsLink"
          href="https://quarto.org/docs/get-started/"
          target="_blank"
        >
          download and install Quarto
        </a>
        for other development environments.
      </p>
      <p>
        <a
          ref="learnMoreLink"
          href="https://quarto.org/"
          target="_blank"
        >Learn more about Quarto</a>
      </p>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'InstallQuarto',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>
