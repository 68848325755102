<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div
      class="flex"
      data-automation="unpublished-title"
    >
      <h1
        ref="title"
        class="sectionTitle focusedTitle"
        tabindex="-1"
      >
        Unpublished Content
      </h1>
    </div>

    <EmbeddedStatusMessage
      v-show="!loaded"
      :show-close="false"
      message="Getting unpublished content..."
      type="activity"
    />

    <div v-show="loaded">
      <p>
        Unpublished content has encountered errors while deploying
        and is not available for viewing. It may need to be redeployed.
      </p>

      <div v-if="hasUnpublishedContent">
        <RSTable :columns="tableHeaders">
          <RSTableRow
            v-for="app in unpublishedContent"
            :key="app.id"
          >
            <!-- Content name w/ icon -->
            <RSTableCell
              :cell-id="`${app.id}-name`"
              :has-icon="true"
              :fill="true"
              data-automation="content-row-icon"
            >
              <router-link
                :to="{ name: 'apps', params: { idOrGuid: app.guid } }"
                :title="tooltip(app.contentType())"
                class="content-table__content-name"
                data-automation="content-row-title"
              >
                <div>
                  <i class="rs-icon rs-icon--large typeUnpublished" />
                </div>
                <div
                  class="content-table__display-name"
                >
                  {{ app.displayName || 'Untitled' }}
                </div>
              </router-link>
            </RSTableCell>

            <!-- Content type -->
            <RSTableCell data-automation="content-row-type">
              {{ descriptions(app.contentType()) }}
            </RSTableCell>

            <!-- Content author (owner info is available) -->
            <RSTableCell
              v-if="hasOwner(app)"
              :cell-id="`${app.id}-author`"
              data-automation="content-row-author-cell"
            >
              <router-link
                :to="{ name: 'people.users.profile', params: { guid: app.ownerGuid } }"
                title="Go to content owner's profile"
                data-automation="content-row-author"
              >
                {{ ownerName(app) }}
              </router-link>
            </RSTableCell>
            <!-- Content author (empty cell b/c owner info is missing) -->
            <RSTableCell
              v-else
              data-automation="content-row-author-cell"
            />

            <!-- Content last deployed date -->
            <RSTableCell data-automation="content-row-updated">
              {{ lastDeployedTime(app) }} {{ fromGit(app.git) }}
            </RSTableCell>
          </RSTableRow>
        </RSTable>

        <RSPager
          v-if="showPagination"
          :disable-left-actions="disablePagingLeft"
          :disable-right-actions="disablePagingRight"
          :labels="pagerLabels"
          @first-page="goToFirstPage"
          @previous-page="goToPage"
          @next-page="goToPage"
          @last-page="goToLastPage"
        />
      </div>

      <div
        v-if="!hasUnpublishedContent"
        class="emptyListMessage"
      >
        All content has been published.
      </div>
    </div>
  </div>
</template>

<script>
import { getUnpublishedContent } from '@/api/content';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RSPager from '@/elements/RSPager';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { activeTime } from '@/utils/activeTime.filter';
import { contentDescriptions, contentListHeaders, contentTooltips, displayOwnerName, fromGit, hasOwner } from '@/utils/contentList';
import { truncate } from '@/utils/truncate.filter';
import { mapMutations } from 'vuex';

// number of records to display at a time
const maxRecords = 10;

export default {
  name: 'UnpublishedContentView',
  components: {
    EmbeddedStatusMessage,
    RSTable,
    RSTableRow,
    RSTableCell,
    RSPager,
  },
  data() {
    return {
      unpublishedContent: [],
      loaded: false,
      continuation: '',
      start: 0,
      count: 0,
      total: 0,
    };
  },
  computed: {
    hasUnpublishedContent() {
      return Boolean(this.unpublishedContent.length);
    },
    showPagination() {
      return this.total > maxRecords;
    },
    disablePagingLeft() {
      return this.start === 0;
    },
    disablePagingRight() {
      return this.start + this.count === this.total;
    },
    tableHeaders() {
      return ['name', 'type', 'author', 'lastDeployedTime'].map(header => {
        const label = contentListHeaders[header];
        if (header === 'name') {
          return { label, width: '100%' };
        }
        return label;
      });
    },
    pagerLabels() {
      return {
        first: 'Newest',
        previous: 'Newer',
        next: 'Older',
        last: 'Oldest',
      };
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$refs.title.focus();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    fetchData() {
      const { start, continuation: cont } = this;
      this.loaded = false;
      return getUnpublishedContent({ count: maxRecords, start, cont })
        .then(data => {
          this.unpublishedContent = data.applications || [];
          this.continuation = data.continuation;
          this.count = data.count;
          this.total = data.total;
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.loaded = true;
        });
    },
    fromGit,
    hasOwner,
    ownerName(app) {
      const ownerName = displayOwnerName(app);
      return truncate(ownerName, 18);
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
    goToPage(direction) {
      this.start =
        direction === 'next'
          ? this.start + maxRecords
          : this.start - maxRecords;
      this.fetchData();
    },
    goToFirstPage() {
      this.start = 0;
      this.fetchData();
    },
    goToLastPage() {
      this.start = Math.floor(this.total / maxRecords) * maxRecords;
      this.fetchData();
    },
    tooltip(contentType) {
      return contentTooltips[contentType] || contentTooltips.unknown;
    },
    descriptions(contentType) {
      return contentDescriptions[contentType] || contentDescriptions.unknown;
    },
  },
};
</script>
