<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
    LicenseExpiration

    Renders the license expiration view.
  -->
<template>
  <div
    v-if="display"
    class="broadcastMessage"
  >
    <span class="license-expiration-message">{{ message }}</span>

    <span>
      Please contact <a :href="salesEmailLink">{{ salesEmail }}</a>
    </span>

    <button
      aria-label="Close message"
      class="messageClose"
      @click="dismiss"
    />
  </div>
</template>

<script>
import { SALES_EMAIL } from '@/constants/contacts';
import {
  SERVER_NOTICES_LICENSING_CLOSE
} from '@/store/modules/serverNotices';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { mapMutations, mapState } from 'vuex';

dayjs.extend(relativeTime);

const notificationWindowDays = 15;

export default {
  name: 'LicenseExpiration',
  props: {
    server: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      salesEmail: SALES_EMAIL,
    };
  },
  computed: {
    ...mapState({
      active: state => state.serverNotices.licensing,
    }),
    salesEmailLink() {
      return `mailto:${this.salesEmail}`;
    },
    license() {
      return this.server.license || {};
    },
    daysLeft() {
      return this.license.daysLeft || 0;
    },
    licenseType() {
      if (this.license.status === 'evaluation') {
        return 'trial';
      }
      return 'license';
    },
    enabled() {
      // The server prevents display of this information by including
      // licensing information and indicating that the UI warning is enabled
      // only for publishers and administrators.
      return this.server.licenseExpirationUiWarning === true;
    },
    expired() {
      return this.daysLeft <= 0;
    },
    expiring() {
      return this.daysLeft <= notificationWindowDays;
    },
    display() {
      return this.enabled && this.active && (this.expired || this.expiring);
    },
    message() {
      if (this.expired) {
        return 'Your Posit Connect license has expired. ';
      } else if (this.expiring) {
        const expiresWhen = dayjs().add(this.daysLeft, 'days');
        return `Your Posit Connect ${this.licenseType} expires ${expiresWhen.fromNow()}.`;
      }
      // The "pending" message should never be shown.
      return 'Information about your Posit Connect license is unavailable.';
    }
  },
  methods: {
    ...mapMutations({
      dismiss: SERVER_NOTICES_LICENSING_CLOSE,
    }),
  },
};
// server-notices; serversettings as a prop to to the two types of messages.
</script>
