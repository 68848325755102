<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <nav
    :data-automation="dataAutomation"
    class="rs-pager"
  >
    <div class="rs-pager__left-actions">
      <button
        v-for="action in leftActions"
        :key="action.label"
        :disabled="disableLeftActions"
        :class="['rs-pager__action', action.label]"
        @click="() => pageHandler(action)"
        @keydown.enter="() => pageHandler(action)"
        @keydown.space="() => pageHandler(action)"
      >
        <span class="rs-pager__action-label">
          <span aria-hidden="true">{{ action.icon }}</span> {{ labels[action.label] }}
        </span>
      </button>
    </div>
    <div class="rs-pager__right-actions">
      <button
        v-for="action in rightActions"
        :key="action.label"
        :disabled="disableRightActions"
        :class="['rs-pager__action', action.label]"
        @click="() => pageHandler(action)"
        @keydown.enter="() => pageHandler(action)"
        @keydown.space="() => pageHandler(action)"
      >
        <span class="rs-pager__action-label">
          {{ labels[action.label] }} <span aria-hidden="true">{{ action.icon }}</span>
        </span>
      </button>
    </div>
  </nav>
</template>

<script>
const pageEvents = {
  firstPage: 'first-page',
  previousPage: 'previous-page',
  nextPage: 'next-page',
  lastPage: 'last-page',
};

const firstPage = {
  label: 'first',
  icon: '⇤',
  event: pageEvents.firstPage,
};

const previousPage = {
  label: 'previous',
  icon: '←',
  event: pageEvents.previousPage,
};

const nextPage = {
  label: 'next',
  icon: '→',
  event: pageEvents.nextPage,
};
const lastPage = {
  label: 'last',
  icon: '⇥',
  event: pageEvents.lastPage,
};

export default {
  name: 'RSPager',
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    labels: {
      type: Object,
      default: () => ({
        first: 'First',
        previous: 'Previous',
        next: 'Next',
        last: 'Last',
      }),
    },
    disableLeftActions: {
      type: Boolean,
      default: false,
    },
    disableRightActions: {
      type: Boolean,
      default: false,
    },
    hideFirstAndLast: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    leftActions() {
      return this.hideFirstAndLast ? [previousPage] : [firstPage, previousPage];
    },
    rightActions() {
      return this.hideFirstAndLast ? [nextPage] : [nextPage, lastPage];
    },
  },
  methods: {
    pageHandler(action) {
      this.$emit(action.event, action.label);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-pager {
  display: flex;
  padding: 1rem 2px;
  position: relative;
  justify-content: space-between;
  width: 100%;

  &__left-actions,
  &__right-actions {
    display: flex;

    & > * {
      margin-bottom: 0.3rem;
    }
  }

  &__left-actions {
    justify-content: flex-start;

    & > * {
      margin-right: 0.2rem;
    }
  }

  &__right-actions {
    justify-content: flex-end;

    & > * {
      margin-left: 0.2rem;
    }
  }

  &__action {
    background: white;
    border: 1px solid $color-rounded-button-border;
    border-radius: 1rem;
    color: $color-primary;
    cursor: pointer;
    display: flex;
    font-size: 0.83rem;
    padding: 0.5rem 0.83rem;
    transition: all 0.25s ease;

    & > * {
      margin: 0 0.1rem;
    }

    &:hover:not([disabled]) {
      background: $color-light-grey-2;
      color: $color-secondary-inverse;
    }

    &:disabled {
      color: black;
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:focus-visible {
      @include control-focus;
      border-radius: 1rem;
    }
  }

  @include for-smallest-screens() {
    &__action {
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
    }

    &__right-actions {
      .rs-pager__action {
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
