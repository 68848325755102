<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Option panel for the groups view. It has a stateful search string. It dispatches
  actions into the Groups Vuex module store, which then updates the group list
  which is displayed by the Group View.
-->
<template>
  <OptionsPanel>
    <div class="rsc-options-panel__header">
      <h2 class="rsc-options-panel__header-title">
        Options
      </h2>
      <button
        aria-label="close"
        class="paneCloser"
        @click.prevent="$emit('close')"
      />
    </div>

    <div class="rsc-options-panel__input-group">
      <RSInputSearch
        ref="searchInput"
        v-model.trim="searchString"
        label="Search"
        placeholder="Search for a group..."
        :show-label="false"
        :small="true"
        :show-clear="Boolean(searchString)"
        icon="search"
        name="gop-search"
        data-automation="gop-search-input"
        @change="filterChanged"
        @clear="clearSearch"
      />

      <div class="rs-field">
        <div class="search-help">
          <p v-if="canSearchById">
            Use the Search field to search groups by name or by their <strong>exact</strong> remote identity (UniqueID).
          </p>
          <p v-else>
            Use the Search field to search for groups by name.
          </p>
        </div>
        <div v-if="canAddGroups">
          <p v-if="isRemote">
            You can import a group with the <strong>Import Group</strong> button.
          </p>
          <p v-else>
            You can add a new group with the <strong>Add Group</strong> button.
          </p>
        </div>
      </div>
    </div>
  </OptionsPanel>
</template>

<script>
import OptionsPanel from '@/components/OptionsPanel';
import RSInputSearch from '@/elements/RSInputSearch';
import { debounce } from '@/utils/debounce';
import Focus from '@/utils/focus';

export default {
  name: 'GroupsOptionsPanel',
  components: { OptionsPanel, RSInputSearch },
  directives: { Focus },
  props: {
    canAddGroups: {
      type: Boolean,
      required: true,
    },
    canSearchById: {
      type: Boolean,
      required: true,
    },
    isRemote: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['filterChange', 'close'],
  data() {
    return {
      searchString: '',
    };
  },
  methods: {
    focus() {
      this.$nextTick(() => this.$refs.searchInput.focusElement());
    },
    clearSearch() {
      this.searchString = '';
      this.onChange();
    },
    onChange() {
      this.$emit('filterChange', {
        prefix: this.searchString,
      });
    },
    filterChanged: debounce(300, function() {
      this.onChange();
    }),
  },
};
</script>

<style lang='scss' scoped>
  .search-help {
    margin-bottom: 1rem;
  }
</style>
