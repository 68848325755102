<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <Accordion
    class-when-hovered="accordion__header--hovered"
    class-when-opened="accordion__header--open"
    class-when-closed="accordion__header--closed"
    class-when-selected="accordion-row--selected"
    class-when-not-selected=""
    :selected="selected"
    :is-open="accordionOpen"
    class="accordion"
    @toggle="onToggle"
  >
    <template #header>
      <EnvironmentsTableRowSummary
        :environment="environment"
        :accordion-open="accordionOpen"
        :active-sort="activeSort"
        :readonly="readonly"
        :dialog-mode="dialogMode"
        class="summary"
        @delete="onDelete"
        @edit="onEdit"
        @click="onAccordionBodyClick"
      />
    </template>
    <EnvironmentsTableRowDetails
      :environment="environment"
      class="details"
      @delete="onDelete"
      @edit="onEdit"
    />
  </Accordion>
</template>

<script>

import EnvironmentsTableRowSummary from './EnvironmentsTableRowSummary';
import EnvironmentsTableRowDetails from './EnvironmentsTableRowDetails';
import Accordion from '@/components/Accordion';

export default {
  name: 'EnvironmentsTableRow',
  components: {
    EnvironmentsTableRowSummary,
    EnvironmentsTableRowDetails,
    Accordion,
  },
  props: {
    environment: {
      type: Object,
      required: true,
    },
    activeSort: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dialogMode: {
      type: Boolean,
      default: false,
    },
    currentlySelectedGuid: {
      type: String,
      default: '',
    }
  },
  emits: ['click', 'delete', 'edit'],
  data() {
    return {
      accordionOpen: false,
    };
  },
  computed: {
    accordionBodyClass() {
      return '';
    },
    selected() {
      return this.environment.guid === this.currentlySelectedGuid;
    },
  },
  methods: {
    onEdit() {
      this.$emit('edit', this.environment);
    },
    onDelete() {
      this.$emit('delete', this.environment);
    },
    onToggle() {
      this.accordionOpen = !this.accordionOpen;
    },
    onAccordionBodyClick() {
      if (!this.dialogMode) {
        this.onToggle();
      } else {
        this.$emit('click', this.environment);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.accordion {
  padding-top: 0.5rem;
  margin-top: 0.3rem;

  &__header {
    &--hovered {
      border: 1px solid $color-light-grey-2;
      padding-bottom: 0.5rem;
      background-color: $color-light-grey;
    }
    &--open {
      border: 1px solid $color-dark-grey-3;
    }
    &--closed {
      border: 1px solid $color-white;
      padding-bottom: 0.5rem;
    }
  }
}
.summary {
  padding-left: 0.6rem;
}
.details {
  padding: 0.5rem 0.6rem;
}
.accordion-row {
  &--selected {
    background-color: $color-light-blue;
  }
}
</style>
