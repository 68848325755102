<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Contains different href targets to visit specific product documentation
-->
<template>
  <div class="layoutMenuOnPage">
    <div class="menu">
      <div class="menuItems">
        <div class="menuItemContainer">
          <h2>
            <a
              :href="userUrl"
              target="_blank"
              class="menuItem userGuide"
              data-automation="documentation-userguide"
            >User Guide</a>
          </h2>
          <div class="standardDescription">
            This guide will help a user configure and deploy content to Posit Connect.
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="adminUrl"
              target="_blank"
              class="menuItem adminGuide"
              data-automation="documentation-adminguide"
            >Admin Guide</a>
          </h2>
          <div class="standardDescription">
            This guide will help an administrator install and configure Posit Connect on a managed
            server. You will learn how to install the product on different operating systems,
            configure authentication, and monitor system resources.
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="apiReference"
              target="_blank"
              class="menuItem apiDocs"
              data-automation="documentation-apidocs"
            >Server API</a>
          </h2>
          <div class="standardDescription">
            <span>
              The Server API lets you interact with Posit Connect from scripts,
              code, and external tools. The
              <a
                :href="apiCookbook"
                data-automation="documentation-apicookbook"
                target="_blank"
              >
                Server API Cookbook
              </a>
              has code examples to help you get started using the API. The
              <a
                :href="apiReference"
                data-automation="documentation-apireference"
                target="_blank"
              >
                Server API Reference
              </a>
              describes the available
              endpoints. A Swagger-compliant specification of the API is available in
              <a
                :href="apiDocsSwaggerYAML"
                target="_blank"
              >
                YAML
              </a>
              and
              <a
                :href="apiDocsSwaggerJSON"
                target="_blank"
              >
                JSON
              </a>.
            </span>
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              href="https://support.posit.co/hc/en-us/sections/205987508-RStudio-Connect"
              target="_blank"
              class="menuItem faq"
              data-automation="documentation-faq"
            >FAQ</a>
          </h2>
          <div class="standardDescription">
            Get answers to frequently asked questions.
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              href="https://support.posit.co/hc/en-us/sections/204688508-RStudio-Connect"
              target="_blank"
              class="menuItem articles"
              data-automation="documentation-articles"
            >Articles</a>
          </h2>
          <div class="standardDescription">
            Articles and additional documentation on the use and administration of Posit Connect.
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="releaseNotes"
              target="_blank"
              class="menuItem releaseNotes"
              data-automation="documentation-releasenotes"
            >Release Notes</a>
          </h2>
          <div class="standardDescription">
            Get all the information on the latest, and previous, releases of Posit Connect.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { docsPath } from '@/utils/paths';

export default {
  name: 'DocumentationViewItems',
  computed: {
    userUrl() {
      return docsPath('user/');
    },
    adminUrl() {
      return docsPath('admin/');
    },
    releaseNotes() {
      return docsPath('news/');
    },
    apiReference() {
      return docsPath('api/');
    },
    apiCookbook() {
      return docsPath('cookbook/');
    },
    apiDocsSwaggerYAML() {
      return docsPath('api/swagger.yaml');
    },
    apiDocsSwaggerJSON() {
      return docsPath('api/swagger.json');
    },
  },
};
</script>

<style scoped lang="scss">

.standardDescription {
  line-height: 1.5em;
}

.layoutMenuOnPage {
  .menuItem {
    &.userGuide {
      background-image: url(/images/elements/navUserGuide.svg);
    }
    &.adminGuide {
      background-image: url(/images/elements/navAdminGuide.svg);
    }
    &.faq {
      background-image: url(/images/elements/navFAQ.svg);
    }
    &.articles {
      background-image: url(/images/elements/navArticles.svg);
    }
    &.releaseNotes {
      background-image: url(/images/elements/navReleaseNotes.svg);
    }
    &.apiDocs {
      background-image: url(/images/elements/navApiDocs.svg);
    }
  }
}
</style>
