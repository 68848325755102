<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <LandingPage
    v-if="serverSettings.customizedLanding"
    data-test="custom-landing-page"
  />
  <div
    v-else
    id="homeContent"
    class="limitBandContentWidth"
    data-test="home-content"
  >
    <div class="band first rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <h1
            class="tagline"
            data-automation="welcome-tagline"
          >
            Welcome to Posit Connect
          </h1>
          <div>
            Distribute data science across your organization.
          </div>
          <div
            v-if="serverSettings.authentication.handlesLogin"
            class="actions"
          >
            <!-- eslint-disable vuejs-accessibility/no-autofocus -->
            <router-link
              :to="{ name: 'login_view' }"
              class="bigButton"
              data-test="get-started-button"
              autofocus
            >
              Get Started
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="band second rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            One button deployment of:
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://shiny.posit.co/"
                >Shiny applications</a>
              </li>
              <li>
                Python applications built with
                <a
                  target="_blank"
                  href="https://flask.palletsprojects.com/"
                >Flask</a>, <a
                  target="_blank"
                  href="https://dash.plotly.com/"
                >Dash</a>,
                <a
                  target="_blank"
                  href="https://bokeh.org/"
                >Bokeh</a>, or
                <a
                  target="_blank"
                  href="https://streamlit.io/"
                >Streamlit</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://rmarkdown.rstudio.com/"
                >R Markdown documents</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://jupyter.org/"
                >Jupyter Notebooks</a>
              </li>
              <li>Static plots and graphs</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="band third rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            Manage and control access to the work you've shared with others -
            and easily see the work they've shared with you
          </div>
        </div>
      </div>
    </div>

    <div class="band fourth rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            "Hands free" scheduling of updates to your documents and automatic email distribution
          </div>
        </div>
      </div>
    </div>

    <div class="band fifth">
      <div class="bandContent">
        <div class="blurb">
          <div>
            For more information on running Posit Connect in your organization please visit
            <a
              target="_blank"
              href="https://posit.co/products/enterprise/connect/"
            >https://posit.co/products/enterprise/connect/</a>.
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter';
import LandingPage from '@/components/Landing/LandingPage';
import {
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { SHRINK_HEADER } from '@/store/modules/navigation';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'WelcomeView',
  components: {
    AppFooter,
    LandingPage,
  },
  computed: {
    messages() {
      return {
        loginError: 'There was a problem logging you in. Please try again. ' +
          `If the problem persists, please ask your ${this.serverSettings.systemDisplayName}'s administrator for assistance.`,
        policyError: 'Your username is not allowed because it is prohibited by policy. ' +
          `Please ask your ${this.serverSettings.systemDisplayName} administrator for assistance.`,
      };
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    })
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      statusInfoMessage: SHOW_INFO_MESSAGE,
      statusErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    ...mapMutations({
      shrinkMainHeader: SHRINK_HEADER
    }),
    init() {
      this.setErrorMessage();
      this.shrinkMainHeader(this.serverSettings.customizedLanding);

      if (this.serverSettings.publicWarning) {
        this.statusInfoMessage({ message: this.serverSettings.publicWarning });
      }
    },
    setErrorMessage() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('msg')) {
        const defaultErrorMessage = 'An unexpected error has occurred';
        const errorMessage =
          this.messages[urlParams.get('msg')] || defaultErrorMessage;
        this.statusErrorMessage({ message: errorMessage });
      }
    },
  },
};
</script>
