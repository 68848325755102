<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="loading"
      :show-close="false"
      message="Getting members..."
      type="activity"
    />

    <RSTable
      v-if="hasMembers"
      :columns="tableHeaders"
      data-automation="group-members__list"
    >
      <RSTableRow
        v-for="(member, index) in members"
        :key="member.guid"
        :row-id="member.guid"
        :deletable="canRemoveGroupMember(member)"
        :delete-button-label="formatRemoveGroupMember(member, group)"
        :row-label="formatNavigateUser(member)"
        :clickable="true"
        @click="navigateUser(member)"
        @delete="$emit('remove-member', { guid: member.guid, index })"
      >
        <RSTableCell
          :has-icon="true"
          :fill="true"
        >
          <RSPrincipalInfo
            :initials="member.displayInitials"
            :name="member.displayName"
            :status="member.displayStatuses"
          />
        </RSTableCell>
        <RSTableCell>{{ memberRoleName(member) }}</RSTableCell>
        <RSTableCell>
          {{ memberActiveTime(member) }}
        </RSTableCell>
      </RSTableRow>
    </RSTable>

    <div
      v-else
      class="emptyListMessage"
      data-automation="members__list--empty"
    >
      This group has no members
    </div>

    <RSPager
      v-show="showPager"
      :disable-left-actions="disablePreviousPagination"
      :disable-right-actions="disableNextPagination"
      @first-page="$emit('goto-page', 'first')"
      @previous-page="$emit('goto-page', 'previous')"
      @next-page="$emit('goto-page', 'next')"
      @last-page="$emit('goto-page', 'last')"
    />
  </div>
</template>

<script>
import UserRoles from '@/api/dto/userRole';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RSPager from '@/elements/RSPager';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';
import { activeTime } from '@/utils/activeTime.filter';
import upperFirst from 'lodash/upperFirst';

export default {
  name: 'GroupMemberList',
  components: {
    RSPager,
    RSTable,
    RSTableCell,
    RSTableRow,
    RSPrincipalInfo,
    EmbeddedStatusMessage,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    members: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
  },
  emits: ['goto-page', 'remove-member'],
  computed: {
    hasMembers() {
      return this.members.length > 0;
    },
    tableHeaders() {
      const hasRemovableMembers = this.members.some(
        this.canRemoveGroupMember
      );
      return [
        { label: 'User', width: '100%' },
        { label: 'Role' },
        {
          label: 'Last Active',
          // 2 to account for delete icon, 1 otherwise
          size: hasRemovableMembers ? 2 : 1,
        },
      ];
    },
    showPager() {
      // TODO: the server currently does not support pagination for group members.
      // Created https://github.com/rstudio/connect/issues/27060 to implement pagination
      // for this view. Until then, we'll just hide the pager.
      return false; // this.totalPages > 1;
    },
    disablePreviousPagination() {
      return this.currentPage === 1;
    },
    disableNextPagination() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    memberActiveTime(member) {
      return activeTime(member.activeTime);
    },
    memberRoleName(member) {
      return upperFirst(UserRoles.stringOf(member.userRole));
    },
    canRemoveGroupMember(user) {
      const { currentUser, group, serverSettings } = this;
      return currentUser.canDeleteGroupMember(group, serverSettings, user);
    },
    formatRemoveGroupMember(user, group) {
      return `Remove ${user.displayName} from group ${group.name}`;
    },
    formatNavigateUser(user) {
      return `Go to ${user.displayName}'s profile`;
    },
    navigateUser(user) {
      this.$router.push({ name: 'people.users.profile', params: { guid: user.guid } });
    },
  },
};
</script>
